const moduleTypes = {
  CHAPTER: 'CHAPTER',
  QUIZ: 'QUIZ',
};

const sectionTypes = {
  TEXT: 'TEXT',
  VIDEO: 'VIDEO',
  PROCESS: 'PROCESS',
  FLASHCARD: 'FLASHCARD',
  IMAGE: 'IMAGE',
};

export {
  moduleTypes,
  sectionTypes,
};
