// @flow
import { action, decorate, observable } from 'mobx';
import { v4 as uuid } from 'uuid';

import Api from '../../api';
import { moduleTypes } from '../../components/LessonBuilder/constants/typesConstants';

class LessonBuilderStore {
  isBuilderLoading = false;

  currenLessonId = null;

  currentModuleId = null;

  builder = null;

  getLessonBuilder = async (lessonId) => {
    try {
      this.isBuilderLoading = true;

      const { data } = await Api.getLessonBuilder(lessonId);

      this.builder = data;

      return data;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  getLessonBuilderModules = () => (this.builder?.lessonModules || []);

  getLessonBuilderChaptersSections = (moduleId) => {
    const module = this.builder?.lessonModules?.find((m) => m.recordId === moduleId);

    return module?.chapterSections || [];
  };

  getLessonBuilderKnowledgeCheckQuestions = (moduleId) => {
    const module = this.builder?.lessonModules?.find((m) => m.recordId === moduleId);

    return module?.quizQuestions || [];
  };

  getChapter = (moduleId) => this.builder?.lessonModules?.find((m) => m.recordId === moduleId);

  upsertLessonBuilder = async (lessonBuilder) => {
    try {
      this.isBuilderLoading = true;

      const { data } = await Api.upsertLessonBuilder(lessonBuilder);

      return data;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteLesson = async (lessonId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteLessonBuilder(lessonId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  addModule = (
    index = null, type = moduleTypes.CHAPTER,
    successCallback = (newModule, updatedModules) => {},
    errorCallback = () => {},
  ) => {
    const modules = this.builder?.lessonModules || [];
    const lessonId = this.builder?.recordId;

    const { chaptersLength, checksLength } = modules.reduce(
      (acc, module) => {
        if (module.type === moduleTypes.CHAPTER) {
          acc.chaptersLength++;
        } else {
          acc.checksLength++;
        }

        return acc;
      },
      { chaptersLength: 0, checksLength: 0 },
    );

    if (!chaptersLength && type === moduleTypes.QUIZ) {
      errorCallback();

      return;
    }

    const name = `${type === moduleTypes.CHAPTER ? `Chapter ${chaptersLength + 1}` : `Knowledge Check ${checksLength + 1}`}`;

    const newModule = {
      recordId: uuid(),
      isNew: true,
      name,
      description: '',
      lessonId,
      ...(type === moduleTypes.CHAPTER
        ? { chapterSections: [] } // Add sections for CHAPTER
        : {
          quizQuestions: [], // Add questions and other attributes for QUIZ
          questionOrder: 'listed-order',
          thresholdToPass: null,
          preAssessment: 1,
        }),
      type,
    };

    let updatedModules;

    if (index === null || index >= modules.length) {
      updatedModules = [...modules, newModule];
    } else {
      updatedModules = [
        ...modules.slice(0, index + 1),
        newModule,
        ...modules.slice(index + 1),
      ];
    }

    this.setLessonBuilderModules(updatedModules);
    successCallback(newModule, updatedModules);
  };

  deleteModule = async (moduleId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteModule(this.currenLessonId, moduleId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteSection = async (sectionId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteSection(
        this.currenLessonId,
        this.currentModuleId,
        sectionId,
      );

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteCard = async (cardId) => {
    try {
      this.isBuilderLoading = true;
      const res = await Api.deleteCard(cardId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteQuestion = async (questionId) => {
    try {
      this.isBuilderLoading = true;

      const res = await Api.deleteQuestion(this.currenLessonId, this.currentModuleId, questionId);

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  deleteChoice = async (questionId, choiceId) => {
    try {
      this.isBuilderLoading = true;

      const res = await Api.deleteChoice(
        this.currenLessonId,
        this.currentModuleId,
        questionId,
        choiceId,
      );

      return res;
    } catch (err) {
      console.log('err', err);

      return null;
    } finally {
      this.isBuilderLoading = false;
    }
  };

  setLessonData = async (builder) => {
    this.currenLessonId = builder.lessonId || this.currenLessonId;
    this.currentModuleId = builder.moduleId || this.currentModuleId;

    this.builder = builder;
  };

   setLessonBuilderModules = (modules) => {
     this.builder = {
       ...this.builder,
       lessonModules: modules,
     };
   };

  setChapterSections = (moduleId, sections) => {
    const module = this.builder.lessonModules?.find((m) => m.recordId === moduleId);

    if (module) {
      this.builder = {
        ...this.builder,
        lessonModules: this.builder.lessonModules.map((m) => {
          if (m.recordId === moduleId) {
            return {
              ...m,
              chapterSections: sections,
            };
          }

          return m;
        }),
      };
    }
  };

  setKnowledgeCheckQuestions = (moduleId, questions) => {
    const module = this.builder.lessonModules?.find((m) => m.recordId === moduleId);

    if (module) {
      this.builder = {
        ...this.builder,
        lessonModules: this.builder.lessonModules.map((m) => {
          if (m.recordId === moduleId) {
            return {
              ...m,
              quizQuestions: questions,
            };
          }

          return m;
        }),
      };
    }
  };
}

decorate(LessonBuilderStore, {
  isBuilderLoading: observable,
  getLessonBuilder: action,
  upsertLessonBuilder: action,
  deleteLesson: action,
  addModule: action,
  deleteModule: action,
  deleteSection: action,
  deleteCard: action,
  deleteQuestion: action,
  deleteChoice: action,
  setLessonData: action,
  setChapterSections: action,
  builder: observable,
  getLessonBuilderChaptersSections: action,
  getLessonBuilderModules: action,
  setLessonBuilderModules: action,
  setKnowledgeCheckQuestions: action,
  getLessonBuilderKnowledgeCheckQuestions: action,
});

export default new LessonBuilderStore();
